import { inject } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  RedirectCommand,
  ResolveFn,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router'

import { CurrentUserService } from '../../services/current-user-service'
import { HealthCheckService } from '../../services/health-check-service'
import { ReleasesService } from '../../services/releases-service'

var intialResolutionCount = 0 //ffas

export const OnInitialRouteResolving: CanActivateFn = async (
  route: ActivatedRouteSnapshot,
  _: RouterStateSnapshot
) => {
  console.log('entered OnInitialRouteResolving')

  intialResolutionCount++

  if (intialResolutionCount > 1) {
    console.log(`skipping OnInitialRouteResolving ({initialResolutionCount})`)
    return true
  }

  var healthCheckService = inject(HealthCheckService)

  const router = inject(Router)
  var transitionResult = await healthCheckService.initialise()
  var result = handleTransitionResult(route, router, transitionResult)
  console.log('leaving OnInitialRouteResolving')
  return result
}

export const OnInitialRouteResolved: ResolveFn<void> = async (
  _: ActivatedRouteSnapshot,
  __: RouterStateSnapshot
) => {
  console.log('entered OnInitialRouteResolved')

  if (intialResolutionCount > 1) {
    console.log(`skipping OnInitialRouteResolved ({initialResolutionCount})`)
    return
  }

  var currentUserService = inject(CurrentUserService)
  var releasesService = inject(ReleasesService)
  const router = inject(Router)

  await currentUserService.initialise()
  var outcome = await releasesService.initialise()

  console.log('leaving OnInitialRouteResolved')

  if (outcome === true) return
  if (outcome === false) throw new Error('unexpected argument')
  return new RedirectCommand(router.parseUrl('/' + outcome))
}

export const AuthActionHandler: CanActivateChildFn = async (
  route: ActivatedRouteSnapshot,
  _: RouterStateSnapshot
) => {
  console.log('entered AuthActionHandler')

  const currentUserService = inject(CurrentUserService)
  const router = inject(Router)

  var transitionResult: string | boolean = true
  switch (route.routeConfig?.path) {
    case 'auth-sso':
      //TODO: HEALTH CHECK DOESN'T RUN IN THIS PATH
      var actor = route.queryParams['actor']
      transitionResult = await currentUserService.performSingleSignOn(actor)

      break
    case 'log-out':
      transitionResult = await currentUserService.performLogout()
      break
    case 'log-in':
      var signUp: boolean = !!route.queryParams['sign-up']
      await currentUserService.performLogin(signUp)
      transitionResult = false
      break
    default:
      transitionResult = true
      break
  }
  var result = handleTransitionResult(route, router, transitionResult)
  console.log('leaving AuthActionHandler')

  return result
}

function handleTransitionResult(
  currentRoute: ActivatedRouteSnapshot,
  router: Router,
  outcome: string | boolean
): boolean | UrlTree {
  if (outcome === true) return true
  if (outcome === false) return false
  return createSiblingUrlTree(currentRoute, router, outcome)
}

function createSiblingUrlTree(
  route: ActivatedRouteSnapshot,
  router: Router,
  leaf: string
): UrlTree {
  const urlSegments = route.pathFromRoot
    .map((snapshot) => snapshot.url)
    .reduce((acc, urlSegments) => acc.concat(urlSegments), [])
    .filter((urlSegment) => !!urlSegment)
  const currentUrl = urlSegments.map((urlSegment) => urlSegment.path)
  currentUrl.pop()
  const relativeUrl = currentUrl.concat([leaf])
  return router.createUrlTree(relativeUrl)
}
