import { Component, OnInit } from '@angular/core'
import {
  Country,
  LatestDataModel as LatestDataServerModel,
  ReleaseDataService,
  TopRoutesInterval,
  TopRoutesMeasure
} from '@cts/cedar-public-api'
import { ReferenceDataService } from 'projects/cedar-public/src/services/reference-data-service'
import { Observable } from 'rxjs'

import { LatestDataModel, TopRoutesSort, TopRoutesTableModel } from './latest-data-model'

@Component({
  selector: 'app-latest-data',
  templateUrl: './latest-data.component.html',
  styleUrls: ['./latest-data.component.scss']
})
export class LatestDataComponent implements OnInit {
  public TopRoutesMeasure: typeof TopRoutesMeasure = TopRoutesMeasure
  public TopRoutesInterval: typeof TopRoutesInterval = TopRoutesInterval

  public serverData: LatestDataServerModel | null = null
  public model = new LatestDataModel()
  referenceCountries: Observable<Country[]>

  constructor(
    private releaseDataService: ReleaseDataService,
    referenceDataService: ReferenceDataService
  ) {
    this.referenceCountries = referenceDataService.countries$
  }

  ngOnInit(): void {
    this.releaseDataService.getLatestDataModel().subscribe((serverData) => {
      this.serverData = serverData

      var model = this.model
      model.leftPanel = serverData.leftPanel
      model.globalVolPiChartData = serverData.globalVolPiChartData
      model.commentaryHtml = serverData.commentaryHtml
      model.regionPanelSet = serverData.regionPanelSet
      model.topCountries.sourceData = serverData.topCountries
      model.topPorts.sourceData = serverData.topPorts
      this.refreshTables()
    })
  }

  selectTopRoutesMeasure(model: TopRoutesTableModel, measure: TopRoutesMeasure): void {
    if (model.selectedMeasure !== measure) {
      if (
        model.selectedInterval == TopRoutesInterval.Ytd &&
        measure == TopRoutesMeasure.PriceIndices
      ) {
        model.selectedInterval = TopRoutesInterval.Mom
      }
      model.selectedMeasure = measure
      this.refreshTables()
    }
  }

  selectTopRoutesInterval(model: TopRoutesTableModel, interval: TopRoutesInterval): void {
    if (model.selectedInterval !== interval) {
      model.selectedInterval = interval
      this.refreshTables()
    }
  }

  toggleTopRoutesSort(model: TopRoutesTableModel, attribute: TopRoutesSort): void {
    var sortDirection = model.selectedSortDirection
    if (model.selectedSortField !== attribute) {
      model.selectedSortField = attribute
      sortDirection = 'asc'
    } else {
      sortDirection = sortDirection === 'asc' ? 'desc' : 'asc'
    }

    model.selectedSortDirection = sortDirection
    this.refreshTables()
  }

  refreshTables() {
    var model = this.model
    var serverData = this.serverData!

    //assign
    model.topRisers.rows = [
      ...(serverData.topRisers.find(
        (table) =>
          table.intervalType == model.topRisers.selectedInterval &&
          table.measureType == model.topRisers.selectedMeasure
      )?.items || [])
    ]

    model.topFallers.rows = [
      ...(serverData.topFallers.find(
        (table) =>
          table.intervalType == model.topFallers.selectedInterval &&
          table.measureType == model.topFallers.selectedMeasure
      )?.items || [])
    ]

    //sort
    var sense = this.model.topRisers.selectedSortDirection === 'asc' ? 1 : -1
    var attribute = this.model.topRisers.selectedSortField as string
    this.model.topRisers.rows.sort((a, b) => {
      var aProp: any = (a as any)[attribute]
      var bProp: any = (b as any)[attribute]
      var comparisonResult = aProp > bProp ? 1 : aProp < bProp ? -1 : 0
      return comparisonResult * sense
    })

    sense = this.model.topFallers.selectedSortDirection === 'asc' ? 1 : -1
    attribute = this.model.topFallers.selectedSortField as string
    this.model.topFallers.rows.sort((a, b) => {
      var aProp: any = (a as any)[attribute]
      var bProp: any = (b as any)[attribute]
      var comparisonResult = aProp > bProp ? 1 : aProp < bProp ? -1 : 0
      return comparisonResult * sense
    })
  }
}
