import { Component, HostListener, OnDestroy, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { User } from '@auth0/auth0-angular'
import { initFlowbite } from 'flowbite'
import { filter, Subscription } from 'rxjs'

import { CurrentUserService } from '../../../../services/current-user-service'
import { CONFIG as configuration } from './config'
import { LeftMenuItemConfig, RightMenuItemConfig } from './menu-item-config'

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss']
})
export class MenuBarComponent implements OnInit, OnDestroy {
  public leftMenuItems: LeftMenuItemConfig[] = configuration.leftMenuItems
  public rightMenuItems: RightMenuItemConfig[] = []
  public allMenuItems: LeftMenuItemConfig[] = []

  public user: User | null = null
  private subscription: Subscription | null = null

  constructor(
    private currentUserService: CurrentUserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.subscription = this.currentUserService.user$.subscribe((user) => {
      this.rightMenuItems = user
        ? configuration.authenticatedMenuItems
        : configuration.unauthenticatedMenuItems

      this.allMenuItems = [...this.leftMenuItems, ...this.rightMenuItems]
      this.user = user
    })
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((_: any) => {
        this.toggleDropdown(null, null)
        document.getElementById('mobile-menu')?.classList.add('hidden')
        document.getElementById('mobile-menu')?.classList.remove('block')
        initFlowbite()
      })
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe()
    this.subscription = null
  }

  public currentDropDown: any

  toggleDropdown($event: MouseEvent | null, menuParent: any) {
    this.currentDropDown = this.currentDropDown === menuParent ? null : menuParent
    if ($event) $event.stopPropagation()
  }

  isExpanded(menuParent: any): boolean {
    return this.currentDropDown === menuParent
  }

  @HostListener('document:click', ['$event'])
  onclick(event: MouseEvent): void {
    const target = event.target as HTMLElement
    const clickedInside = target.closest('.relative .group')
    if (!clickedInside) {
      this.toggleDropdown(null, null)
    }
  }
}
