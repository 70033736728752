<main class="flex-1">
  <div *ngIf="serverData">
    <section class="main-wrapper mt-[72px] relative grid grid-cols-4 bg-secondary-100 gap-8 pb-10">
      <div class="col-span-4 lg:col-span-1 pt-12">
        <div class="h-32 lg:h-44 border-b border-black/10">
          <div
            class="flex flex-col gap-2"
            title="Latest data for the current month: {{ model.leftPanel.month }}">
            <span class="text-3xl font-medium leading-6">Latest Data</span>
            <span class="text-sm font-medium leading-6 text-secondary-550">
              {{ model.leftPanel.month }}
            </span>
          </div>
        </div>
        <div>
          <div
            class="border-b border-black/10 py-8"
            title="Total global container volume, {{ model.leftPanel.month }}: {{
              model.leftPanel.volume.value.toLocaleString()
            }} TEU">
            <span class="text-sm font-medium leading-6 text-secondary-550"
              >Global Container Volume</span
            >
            <div class="flex justify-between items-end mt-4">
              <div>
                <span class="text-base font-semibold leading-6">{{
                  model.leftPanel.volume.value.toLocaleString()
                }}</span>
                <span class="text-base font-normal leading-6"> TEU</span>
              </div>
              <div class="flex flex-col">
                <div title="Month on month change (%)">
                  <span class="text-sm font-medium leading-6 text-secondary-550">MoM </span>
                  <span class="text-lg font-normal leading-6">
                    {{ model.leftPanel.volume.mom > 0 ? '+' : ''
                    }}{{ model.leftPanel.volume.mom.toLocaleString() }}%
                  </span>
                </div>
                <div title="Year on year change (%)">
                  <span class="text-sm font-medium leading-6 text-secondary-550">YoY </span>
                  <span class="text-lg font-normal leading-6">
                    {{ model.leftPanel.volume.yoy > 0 ? '+' : ''
                    }}{{ model.leftPanel.volume.yoy.toLocaleString() }}%
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="py-8"
            title="Global Price Index, {{ model.leftPanel.month }}: {{
              model.leftPanel.priceIndex.value.toLocaleString()
            }} points (vs 2008 base rate of 100)">
            <span class="text-sm font-medium leading-6 text-secondary-550">Global Price Index</span>
            <div class="flex justify-between items-end mt-4">
              <div>
                <span class="text-base font-semibold leading-6">
                  {{ model.leftPanel.priceIndex.value.toLocaleString() }}
                </span>
                <span class="text-base font-normal leading-6"> pts</span>
              </div>
              <div class="flex flex-col">
                <div title="Month on month change (pts)">
                  <span class="text-sm font-medium leading-6 text-secondary-550">MoM </span>
                  <span class="text-lg font-normal leading-6">
                    {{ model.leftPanel.priceIndex.mom > 0 ? '+' : ''
                    }}{{ model.leftPanel.priceIndex.mom.toLocaleString() }}&nbsp;pts
                  </span>
                </div>
                <div title="Year on year change (pts)">
                  <span class="text-sm font-medium leading-6 text-secondary-550">YoY </span>
                  <span class="text-lg font-normal leading-6">
                    {{ model.leftPanel.priceIndex.yoy > 0 ? '+' : ''
                    }}{{ model.leftPanel.priceIndex.yoy.toLocaleString() }}&nbsp;pts
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-span-4 lg:col-span-3 bg-[#E6E6E6] p-4 sm:p-14"
        title="Chart showing total global container volume and Price Index by month for last 13 months">
        <span class="text-sm font-medium leading-6">Global Container Volume and Price Index</span>
        <div class="mt-8">
          <app-line-chart [data]="model.globalVolPiChartData"> </app-line-chart>
        </div>
      </div>
      <div
        class="col-span-4 bg-white h-96 flex justify-center items-center px-3"
        title="Analysis and commentary from CTS of the latest month's figures">
        <div class="max-w-[600px] flex flex-col items-center gap-8 text-center">
          <div [innerHtml]="model.commentaryHtml"></div>
          <button
            type="button"
            false
            class="w-full bg-black px-4 py-3 h-14 text-sm font-medium leading-6 text-white duration-200 hover:bg-[#605F62] max-w-[227px]"
            [routerLink]="['/subscriptions/subscription-plans']">
            <span>Read more</span>
          </button>
        </div>
      </div>
    </section>
    <section
      class="main-wrapper relative grid grid-cols-4 bg-white gap-4 pb-10"
      title="Browse the latest month's data by Region or SubRegion">
      <span class="col-span-4 mt-10 font-medium text-2xl leading-6">Browse by region</span>

      <div
        class="col-span-4 md:col-span-1 bg-secondary-200 hover:bg-secondary-600 transition duration-300 h-[512px] pt-5 el-parent overflow-hidden">
        @let europe = model.regionPanelSet.europe;
        <a [routerLink]="['/explore-insights/region', europe.id]">
          <div
            class="bg-europe bg-cover bg-no-repeat bg-center flex flex-col justify-between h-full bg-secondary-200 pt-5 transition duration-300 hover:bg-secondary-600 el-parent">
            <div class="flex justify-between items-center pl-4 pr-1">
              <span class="text-secondary-700 font-bold">{{ europe.name }}</span>
            </div>
            <div class="flex flex-col pl-4 pr-1 pb-5 gap-1 el-children min-h-[100px]">
              <ng-container *ngIf="europe.children && europe.children.length">
                <span class="text-xxs text-black/60">Subregions:</span>
                <a
                  *ngFor="let otherChildRegion of europe.children"
                  [routerLink]="['/explore-insights/sub-region', otherChildRegion.id]">
                  <span class="text-xs">{{ otherChildRegion.name }}</span>
                </a>
              </ng-container>
            </div>
          </div>
        </a>
      </div>

      <div class="col-span-4 md:col-span-3 grid grid-cols-2 md:grid-cols-3 gap-4">
        <ng-container *ngFor="let otherRegion of model.regionPanelSet.other">
          <a [routerLink]="['/explore-insights/region', otherRegion.id]">
            <div
              class="col-span-1 flex flex-col justify-between h-full bg-secondary-200 pt-5 transition duration-300 hover:bg-secondary-600 el-parent">
              <div class="flex justify-between items-center pl-4 pr-1">
                <span class="text-secondary-700 font-bold">{{ otherRegion.name }}</span>
              </div>
              <div class="flex flex-col pl-4 pr-1 pb-5 gap-1 el-children min-h-[100px]">
                <ng-container *ngIf="otherRegion.children && otherRegion.children.length">
                  <span class="text-xxs text-black/60">Subregions:</span>
                  <a
                    *ngFor="let otherChildRegion of otherRegion.children"
                    [routerLink]="['/explore-insights/sub-region', otherChildRegion.id]">
                    <span class="text-xs">{{ otherChildRegion.name }}</span>
                  </a>
                </ng-container>
              </div>
            </div>
          </a>
        </ng-container>
      </div>
    </section>
    <section
      class="main-wrapper relative bg-white gap-4 pb-10 w-full"
      title="This month's top region to region trades, risers and fallers">
      <div class="grid grid-cols-1 xl:grid-cols-2 gap-3">
        <span class="col-span-full text-2xl leading-6 font-medium pb-8">Top trades</span>
        <div
          class="col-span-1"
          *ngIf="model.topRisers.rows"
          title="Top {{
            model.topRisers.rows.length
          }} risers this month - region to region volumes and price indices">
          <span class="font-bold text-secondary-550">
            {{ 'Top ' + model.topRisers.rows.length + ' Risers' }}
          </span>
          <div class="bg-secondary-350 py-8 px-6 mt-4 w-full overflow-x-auto">
            <div class="min-w-[450px]">
              <div class="flex justify-between items-center gap-1 pb-6">
                <div class="flex gap-2 items-center">
                  <span
                    class="font-bold text-base cursor-pointer"
                    [ngClass]="{
                      'text-black/40': model.topRisers.selectedMeasure !== TopRoutesMeasure.Volumes
                    }"
                    (click)="selectTopRoutesMeasure(model.topRisers, TopRoutesMeasure.Volumes)">
                    Volumes
                  </span>
                  <span class="font-bold text-base">|</span>
                  <span
                    class="font-bold text-base cursor-pointer"
                    [ngClass]="{
                      'text-black/40':
                        model.topRisers.selectedMeasure !== TopRoutesMeasure.PriceIndices
                    }"
                    (click)="
                      selectTopRoutesMeasure(model.topRisers, TopRoutesMeasure.PriceIndices)
                    ">
                    Price Indices
                  </span>
                </div>
                <div class="flex gap-2 items-center">
                  <span
                    class="font-bold text-base cursor-pointer"
                    title="Month on month change"
                    [ngClass]="{
                      'text-black/40': model.topRisers.selectedInterval !== TopRoutesInterval.Mom
                    }"
                    (click)="selectTopRoutesInterval(model.topRisers, TopRoutesInterval.Mom)">
                    MoM
                  </span>
                  <span class="font-bold text-base">|</span>
                  <span
                    class="font-bold text-base cursor-pointer"
                    title="Year on year change"
                    [ngClass]="{
                      'text-black/40': model.topRisers.selectedInterval !== TopRoutesInterval.Yoy
                    }"
                    (click)="selectTopRoutesInterval(model.topRisers, TopRoutesInterval.Yoy)">
                    YoY
                  </span>

                  <span *ngIf="model.topRisers.selectedMeasure !== TopRoutesMeasure.PriceIndices">
                    <span class="font-bold text-base">|</span>
                    <span
                      class="font-bold text-base cursor-pointer"
                      title="Year to date change"
                      [ngClass]="{
                        'text-black/40': model.topRisers.selectedInterval !== TopRoutesInterval.Ytd
                      }"
                      (click)="selectTopRoutesInterval(model.topRisers, TopRoutesInterval.Ytd)">
                      YTD
                    </span>
                  </span>
                </div>
              </div>
              <table class="table-auto border-collapse w-full">
                <thead>
                  <tr>
                    <th scope="col" class="px-2 pr-4 py-2 bg-black/5 text-start">
                      <div
                        class="flex items-center gap-2 cursor-pointer"
                        (click)="toggleTopRoutesSort(model.topRisers, 'from')">
                        <span class="text-secondary-700 font-medium text-sm leading-4">From</span>
                        <img
                          class="w-fit text-end rem:h-[14px]"
                          src="../assets/fonts/icon-sort.svg"
                          alt="icon" />
                      </div>
                    </th>
                    <th scope="col" class="pr-4 py-2 bg-black/5 text-start">
                      <div
                        class="flex items-center gap-2 cursor-pointer"
                        (click)="toggleTopRoutesSort(model.topRisers, 'to')">
                        <span class="text-secondary-700 font-medium text-sm leading-4">To</span>
                        <img
                          class="w-fit text-end rem:h-[14px]"
                          src="../assets/fonts/icon-sort.svg"
                          alt="icon" />
                      </div>
                    </th>
                    <th scope="col" class="pr-4 py-2 bg-black/5 text-start">
                      <div
                        class="flex items-center gap-2 cursor-pointer"
                        (click)="toggleTopRoutesSort(model.topRisers, 'value')">
                        <span class="text-secondary-700 font-medium text-sm leading-4">
                          {{
                            model.topRisers.selectedInterval === TopRoutesInterval.Yoy
                              ? 'YoY'
                              : model.topRisers.selectedInterval === TopRoutesInterval.Mom
                                ? 'MoM'
                                : 'YTD'
                          }}
                        </span>
                        <img
                          class="w-fit text-end rem:h-[14px]"
                          src="../assets/fonts/icon-sort.svg"
                          alt="icon" />
                      </div>
                    </th>
                    <th scope="col" class="pr-4 py-2 bg-black/5 text-star !max-w-24"></th>
                    <th scope="col" class="pr-2 py-2 bg-black/5 text-start max-w-24"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    onclick=""
                    class="hover:bg-secondary-250"
                    *ngFor="let dataRow of model.topRisers.rows">
                    <td class="px-2 pr-4 py-1">
                      <span class="text-secondary-700 text-sm">{{ dataRow.from }}</span>
                    </td>
                    <td class="pr-4 py-1">
                      <span class="text-secondary-700 text-sm">{{ dataRow.to }}</span>
                    </td>
                    <td class="pr-4 py-1">
                      <span class="text-secondary-700 text-sm whitespace-nowrap">
                        {{ dataRow.value > 0 ? '+' : '' }}{{ dataRow.value | number: '1.0-1' }}
                        {{
                          model.topRisers.selectedMeasure === TopRoutesMeasure.Volumes ? '%' : 'pts'
                        }}
                      </span>
                    </td>
                    <td class="pr-4 py-1 w-24">
                      <button
                        [routerLink]="['/explore-insights/trade']"
                        [queryParams]="{ fromRegionId: dataRow.fromId, toRegionId: dataRow.toId }"
                        type="button"
                        false
                        class="w-full bg-secondary-400 px-6 py-1 h-fit text-sm font-medium leading-6 text-secondary-700 w-[70px] text-xs font-medium cursor-pointer"
                        title="Purchase subscription for this trade">
                        <span>Buy</span>
                      </button>
                    </td>
                    <td
                      class="pr-2 py-1 cursor-pointer"
                      title="View current data for this trade"
                      [routerLink]="['/explore-insights/trade']"
                      [queryParams]="{ fromRegionId: dataRow.fromId, toRegionId: dataRow.toId }">
                      <img
                        class="w-fit text-end rem:h-[16px] max-w-2"
                        src="../assets/fonts/icon-arrow-expand.svg"
                        alt="icon" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          class="col-span-1"
          *ngIf="model.topFallers.rows.length"
          title="Top {{
            model.topFallers.rows.length
          }} fallers this month - region to region volumes and price indices">
          <span class="font-bold text-secondary-550">{{
            'Top ' + model.topFallers.rows.length + ' Fallers'
          }}</span>
          <div class="bg-secondary-350 py-8 px-6 mt-4 w-full overflow-x-auto">
            <div class="min-w-[450px]">
              <div class="flex justify-between items-center gap-1 pb-6">
                <div class="flex gap-2 items-center">
                  <span
                    class="font-bold text-base cursor-pointer"
                    [ngClass]="{
                      'text-black/40': model.topFallers.selectedMeasure !== TopRoutesMeasure.Volumes
                    }"
                    (click)="selectTopRoutesMeasure(model.topFallers, TopRoutesMeasure.Volumes)">
                    Volumes
                  </span>
                  <span class="font-bold text-base">|</span>
                  <span
                    class="font-bold text-base cursor-pointer"
                    [ngClass]="{
                      'text-black/40':
                        model.topFallers.selectedMeasure !== TopRoutesMeasure.PriceIndices
                    }"
                    (click)="
                      selectTopRoutesMeasure(model.topFallers, TopRoutesMeasure.PriceIndices)
                    ">
                    Price Indices
                  </span>
                </div>
                <div class="flex gap-2 items-center">
                  <span
                    class="font-bold text-base cursor-pointer"
                    title="Month on month change"
                    [ngClass]="{
                      'text-black/40': model.topFallers.selectedInterval !== TopRoutesInterval.Mom
                    }"
                    (click)="selectTopRoutesInterval(model.topFallers, TopRoutesInterval.Mom)">
                    MoM
                  </span>
                  <span class="font-bold text-base">|</span>
                  <span
                    class="font-bold text-base cursor-pointer"
                    title="Year on year change"
                    [ngClass]="{
                      'text-black/40': model.topFallers.selectedInterval !== TopRoutesInterval.Yoy
                    }"
                    (click)="selectTopRoutesInterval(model.topFallers, TopRoutesInterval.Yoy)">
                    YoY
                  </span>

                  <span *ngIf="model.topFallers.selectedMeasure !== TopRoutesMeasure.PriceIndices">
                    <span class="font-bold text-base">|</span>
                    <span
                      class="font-bold text-base cursor-pointer"
                      title="Year to Date change"
                      [ngClass]="{
                        'text-black/40': model.topFallers.selectedInterval !== TopRoutesInterval.Ytd
                      }"
                      (click)="selectTopRoutesInterval(model.topFallers, TopRoutesInterval.Ytd)">
                      YTD
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <table class="table-auto border-collapse w-full">
              <thead>
                <tr>
                  <th scope="col" class="px-4 py-2 bg-black/5 text-start">
                    <div
                      class="flex items-center gap-2 cursor-pointer"
                      (click)="toggleTopRoutesSort(model.topFallers, 'from')">
                      <span class="text-secondary-700 font-medium text-sm leading-4">From</span>
                      <img
                        class="w-fit text-end rem:h-[14px]"
                        src="../assets/fonts/icon-sort.svg"
                        alt="icon" />
                    </div>
                  </th>
                  <th scope="col" class="px-4 py-2 bg-black/5 text-start">
                    <div
                      class="flex items-center gap-2 cursor-pointer"
                      (click)="toggleTopRoutesSort(model.topFallers, 'to')">
                      <span class="text-secondary-700 font-medium text-sm leading-4">To</span>
                      <img
                        class="w-fit text-end rem:h-[14px]"
                        src="../assets/fonts/icon-sort.svg"
                        alt="icon" />
                    </div>
                  </th>
                  <th scope="col" class="px-4 py-2 bg-black/5 text-start">
                    <div
                      class="flex items-center gap-2 cursor-pointer"
                      (click)="toggleTopRoutesSort(model.topFallers, 'value')">
                      <span class="text-secondary-700 font-medium text-sm leading-4">
                        {{
                          model.topFallers.selectedInterval === TopRoutesInterval.Yoy
                            ? 'YoY'
                            : model.topFallers.selectedInterval === TopRoutesInterval.Mom
                              ? 'MoM'
                              : 'YTD'
                        }}</span
                      >
                      <img
                        class="w-fit text-end rem:h-[14px]"
                        src="../assets/fonts/icon-sort.svg"
                        alt="icon" />
                    </div>
                  </th>
                  <th scope="col" class="px-4 py-2 bg-black/5 text-star !max-w-24"></th>
                  <th scope="col" class="px-4 py-2 bg-black/5 text-start max-w-24"></th>
                </tr>
              </thead>
              <tbody>
                <tr class="hover:bg-secondary-250" *ngFor="let dataRow of model.topFallers.rows">
                  <td class="px-4 py-1">
                    <span class="text-secondary-700 text-sm">{{ dataRow.from }}</span>
                  </td>
                  <td class="px-4 py-1">
                    <span class="text-secondary-700 text-sm">{{ dataRow.to }}</span>
                  </td>
                  <td class="px-4 py-1">
                    <span class="text-secondary-700 text-sm whitespace-nowrap">
                      {{ dataRow.value > 0 ? '+' : '' }}{{ dataRow.value | number: '1.0-1' }}
                      {{
                        model.topFallers.selectedMeasure === TopRoutesMeasure.Volumes ? '%' : 'pts'
                      }}
                    </span>
                  </td>
                  <td class="px-4 py-1 w-24">
                    <button
                      [routerLink]="['/explore-insights/trade']"
                      [queryParams]="{
                        fromRegionId: dataRow.fromId,
                        toRegionId: dataRow.toId,
                        measureType: model.topFallers.selectedMeasure
                      }"
                      type="button"
                      false
                      class="w-full bg-secondary-400 px-6 py-1 h-fit text-sm font-medium leading-6 text-secondary-700 w-[70px] text-xs font-medium cursor-pointer"
                      title="Purchase subscription for this trade">
                      <span>Buy</span>
                    </button>
                  </td>
                  <td
                    class="px-4 py-1 w-10 cursor-pointer"
                    title="View current data for this trade"
                    [routerLink]="['/explore-insights/trade']"
                    [queryParams]="{
                      fromRegionId: dataRow.fromId,
                      toRegionId: dataRow.toId,
                      measureType: model.topFallers.selectedMeasure
                    }">
                    <img
                      class="w-fit text-end rem:h-[16px] max-w-2"
                      src="../assets/fonts/icon-arrow-expand.svg"
                      alt="icon" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>

    <section class="main-wrapper relative bg-white gap-4 pb-10 w-full flex justify-center pt-10">
      <app-country-port-level-cta></app-country-port-level-cta>
    </section>

    <section class="main-wrapper relative bg-white gap-4 pb-10 w-full pt-10">
      <div class="grid grid-cols-1 xl:grid-cols-2 gap-3">
        @let countryCount = model.topCountries.rows.length;
        <div
          class="col-span-1"
          *ngIf="countryCount"
          title="Top {{ countryCount }} countries, by current month volume ">
          <div class="flex items-center gap-3">
            <span class="font-medium text-2xl text-secondary-800 leading-6 text-nowrap">
              {{ 'Top ' + countryCount + ' Countries' }}
            </span>
            <app-country-auto-complete
              [countrySource]="referenceCountries"></app-country-auto-complete>
          </div>
          <div class="bg-secondary-350 py-8 px-6 mt-4 w-full overflow-x-auto">
            <app-top-countries-table [inputModel]="model.topCountries"></app-top-countries-table>
          </div>
        </div>
        <div
          class="col-span-1"
          *ngIf="model.topPorts.rows"
          title="Top {{ model.topPorts.rows.length }} ports, by current month volume ">
          <div class="flex items-center gap-3">
            <span class="font-medium text-2xl text-secondary-800 leading-6 text-nowrap">
              {{ 'Top ' + model.topPorts.rows.length + ' Ports' }}
            </span>
          </div>
          <div class="bg-secondary-350 py-8 px-6 mt-4 w-full overflow-x-auto">
            <app-top-ports-table [inputModel]="model.topPorts"></app-top-ports-table>
          </div>
        </div>
      </div>
    </section>
  </div>
</main>
<footer class="main-wrapper bg-secondary-100 h-[72px] flex justify-between items-center">
  <span class="text-sm">CTS &#64; 2023. All rights reserved.</span>
  <div class="flex gap-2">
    <a href="">
      <img class="w-fit text-end rem:h-[20px]" src="../assets/fonts/icon-facebook.svg" alt="icon" />
    </a>
    <a href="">
      <img class="w-fit text-end rem:h-[20px]" src="../assets/fonts/icon-linkedin.svg" alt="icon" />
    </a>
  </div>
</footer>
