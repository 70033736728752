import { MenuConfig } from './menu-item-config'

export const CONFIG: MenuConfig = {
  leftMenuItems: [
    {
      title: 'Latest data',
      url: '/latest-data'
    },

    {
      title: 'Explore Insights',
      url: '/explore-insights',

      children: [
        {
          title: 'Region',
          url: '/explore-insights/region'
        },
        {
          title: 'Subregion',
          url: '/explore-insights/sub-region'
        },
        {
          title: 'Trade',
          url: '/explore-insights/trade'
        },
        {
          title: 'Subtrade',
          url: '/explore-insights/sub-trade'
        },
        {
          title: 'Country',
          url: '/explore-insights/country'
        },
        {
          title: 'Port',
          url: '/explore-insights/port'
        }
      ]
    },

    {
      title: 'Subscriptions',
      url: '/subscriptions',

      children: [
        {
          title: 'Subscription plans',
          url: '/subscriptions/subscription-plans'
        },
        {
          title: 'Custom data request',
          url: '/subscriptions/custom-data-request'
        }
      ]
    },

    {
      title: 'FAQ',
      url: '/faq'
    }
  ],
  unauthenticatedMenuItems: [
    {
      title: 'Sign Up',
      url: '/log-in',
      queryParams: { 'sign-up': true }
    },
    {
      title: 'Log in',
      url: '/log-in'
    }
  ],

  authenticatedMenuItems: [
    {
      title: 'My Favourites',
      url: '/my/favourites',
      image: 'icon-bookmark.svg'
    },
    {
      title: 'My Data',
      url: '/my/data',
      image: 'icon-content-paste.svg'
    },
    {
      title: 'My Account',
      image: 'icon-account-circle.svg',
      url: '/my/profile-settings',
      children: [
        {
          title: 'Profile Settings',
          url: '/my/profile-settings'
        },
        {
          title: 'Log out',
          url: 'log-out'
        }
      ]
    }
  ]
}
