import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AuthHttpInterceptor, AuthService } from '@auth0/auth0-angular'
import { mergeMap, Observable, take } from 'rxjs'

import { AppConfig } from '../app-config/app-config'

@Injectable({ providedIn: 'root' })
export class OptionalJwtInterceptor implements HttpInterceptor {
  constructor(
    private auth0Interceptor: AuthHttpInterceptor,
    private auth0Service: AuthService,
    private config: AppConfig
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.allowsAnonymous(req.url)) {
      return this.auth0Interceptor.intercept(req, next)
    } else {
      return this.auth0Service.isAuthenticated$.pipe(
        take(1),
        mergeMap((isAuthenticated) =>
          isAuthenticated ? this.auth0Interceptor.intercept(req, next) : next.handle(req)
        )
      )
    }
  }

  private allowsAnonymous(endpoint: string): boolean {
    var config = this.config
    var allowsAnonymous = !!config.anonymousEndpoints.find((url) =>
      url.endsWith('*')
        ? endpoint
            .toLowerCase()
            .startsWith(`${config.publicApiBasePath}${url}`.replace('*', '').toLowerCase())
        : endpoint.toLowerCase() === `${config.publicApiBasePath}${url}`.toLowerCase()
    )
    return allowsAnonymous
  }
}
