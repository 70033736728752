import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable, Subject, tap } from 'rxjs'

import { API_BASE_PATH } from './API_BASE_PATH'
@Injectable({ providedIn: 'root' })
export class ApiErrorInterceptor implements HttpInterceptor {
  private error = new Subject<any>()
  public error$ = this.error.asObservable()

  constructor(@Inject(API_BASE_PATH) private apiBasePath: string) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var request = req

    var obs = next.handle(request)
    return !req.url.startsWith(this.apiBasePath)
      ? obs
      : obs.pipe(
          tap({
            next: (event) => {
              if (event instanceof HttpResponse) {
                if (event.status == 401) {
                  this.error.next(new HttpErrorResponse({ error: new Error('Unauthorised') }))
                }
              }
              return event
            },
            error: (error) => {
              this.error.next(error)
            }
          })
        )
  }
}
