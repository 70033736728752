import { Routes } from '@angular/router'
import { AuthGuard } from '@auth0/auth0-angular'

import { CustomDataRequestComponent } from '../app/components/custom-data-request/custom-data-request.component'
import { FaqComponent } from '../app/components/faq/faq.component'
import { LatestDataComponent } from '../app/components/latest-data/latest-data.component'
import { SubscriptionPlansComponent } from '../app/components/subscription-plans/subscription-plans.component'
import { ServerErrorComponent } from '../shared/components/server-error/server-error.component'
import {
  AuthActionHandler,
  OnInitialRouteResolved,
  OnInitialRouteResolving
} from './startup-state-handler'

export const ROUTES: Routes = [
  {
    path: 'auth-sso',
    canActivate: [AuthActionHandler],
    children: [] //place-holder for startup sequence
  },

  {
    path: 'log-in',
    canActivate: [AuthActionHandler],
    children: [] //place-holder for startup sequence
  },

  {
    path: 'log-out',
    canActivate: [AuthActionHandler],
    children: [] //place-holder for startup sequence
  },
  {
    path: 'error',
    component: ServerErrorComponent
  },

  {
    path: '',
    canActivate: [OnInitialRouteResolving],
    resolve: { _: OnInitialRouteResolved },
    children: [
      {
        path: 'latest-data',
        component: LatestDataComponent
      },
      {
        path: 'faq',
        component: FaqComponent
      },
      {
        path: 'subscriptions/custom-data-request',
        component: CustomDataRequestComponent
      },
      {
        path: 'subscriptions/subscription-plans',
        component: SubscriptionPlansComponent
      },

      {
        path: '',
        redirectTo: '/latest-data',
        pathMatch: 'full'
      },
      {
        path: 'explore-insights',
        loadChildren: () =>
          import('../explore-insights/explore-insights.module').then((m) => m.ExploreInsightsModule)
      },
      {
        path: 'my',
        loadChildren: () =>
          import('../authenticated/authenticated.module').then((m) => m.AuthenticatedModule),
        canLoad: [AuthGuard]
      }
    ]
  }
]
