import {
  LeftPanel,
  LineChartDataModel,
  RegionPanelSet,
  TopRoutesInterval,
  TopRoutesItem,
  TopRoutesMeasure
} from '@cts/cedar-public-api'

import { TopCountriesTableModel } from '../../../shared/components/top-countries-table/top-countries-table-model'
import { TopPortsTableModel } from '../../../shared/components/top-ports-table/top-ports-table-model'

export type ImexDirection = 'imports' | 'exports'
export type SortDirection = 'asc' | 'desc'
export type TopRoutesSort = 'from' | 'to' | 'value'

export class LatestDataModel {
  public leftPanel!: LeftPanel
  public globalVolPiChartData!: LineChartDataModel
  public commentaryHtml!: string
  public regionPanelSet!: RegionPanelSet

  public topRisers = new TopRoutesTableModel()
  public topFallers = new TopRoutesTableModel()
  public topCountries = new TopCountriesTableModel()
  public topPorts = new TopPortsTableModel()

  constructor() {
    this.topRisers.selectedSortDirection = 'desc'
    this.topFallers.selectedSortDirection = 'asc'
  }
}

export class TopRoutesTableModel {
  selectedSortField: TopRoutesSort = 'value'
  selectedSortDirection!: SortDirection
  selectedMeasure = TopRoutesMeasure.Volumes
  selectedInterval = TopRoutesInterval.Mom
  rows: TopRoutesItem[] = []
}
