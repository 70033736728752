<div class="fixed top-0 left-0 w-full z-[20] bg-white">
  <div class="relative w-full">
    <header class="px-6 rem:h-[72px] flex items-center justify-between border-b border-opacity-20">
      <div class="flex gap-12 lg:gap-16 items-center rem:h-[72px]">
        <a href="/">
          <div>
            <img class="h-14" src="../assets/img/logo-174x174.png" alt="Main Logo" />
          </div>
        </a>
        <div class="hidden md:block">
          <nav>
            <ul class="flex">
              <ng-container *ngFor="let header of leftMenuItems">
                <li class="shrink-0" *ngIf="!header.children">
                  <button
                    type="button"
                    class="w-full rem:h-[72px] px-4 flex justify-center items-center flex-nowrap gap-2 text-sm font-medium leading-6 text-black border-b-2 border-transparent"
                    routerLinkActive="!border-black bg-secondary-300.5 text-secondary-500"
                    appSetAriaCurrentOnActiveLink
                    [routerLink]="header.url"
                    [queryParams]="header.queryParams">
                    <span>{{ header.title }}</span>
                  </button>
                </li>
                <li class="shrink-0" *ngIf="header.children">
                  <div
                    class="relative group"
                    #menuParent
                    [routerLink]="[header.url]"
                    [disableLink]="true">
                    <a
                      routerLinkActive="!border-black bg-secondary-300.5 text-secondary-500"
                      (click)="toggleDropdown($event, menuParent)"
                      class="w-full rem:h-[72px] px-4 flex justify-center items-center flex-nowrap gap-2 text-sm font-medium leading-6 text-black border-b-2 border-transparent">
                      <span>{{ header.title }}</span>
                      <img
                        class="w-fit rem:h-[8px]"
                        src="../assets/fonts/icon-arrow-down.svg"
                        alt="icon" />
                    </a>

                    <div
                      [ngClass]="
                        isExpanded(menuParent)
                          ? 'transition east-out duration-300 transform opacity-100 scale-100 expanded'
                          : 'transition ease-in duration-75 transform opacity-0 scale-95 collapsed'
                      "
                      class="absolute left-0 bg-white p-1 space-y-1 min-w-[240px] border border-black w-full">
                      <ul>
                        <li *ngFor="let child of header.children">
                          <a
                            [routerLink]="child.url"
                            [queryParams]="child.queryParams"
                            class="p-2 h-10 flex items-center text-sm hover:bg-secondary-100">
                            <span>{{ child.title }} </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ng-container>
            </ul>
          </nav>
        </div>
      </div>

      <div class="items-center hidden md:flex">
        <ng-container *ngFor="let header of rightMenuItems">
          <div class="shrink-0" *ngIf="!header.children">
            <button
              type="button"
              class="w-full rem:h-[72px] px-4 flex justify-center items-center flex-nowrap gap-1 text-sm font-medium leading-6 text-black border-b-2 border-transparent"
              routerLinkActive="!border-black bg-secondary-300.5 text-secondary-500"
              appSetAriaCurrentOnActiveLink
              title="{{ header.title }}"
              [routerLink]="header.url"
              [queryParams]="header.queryParams">
              <img
                *ngIf="header.image"
                class="w-fit rem:h-[16px]"
                src="../assets/fonts/{{ header.image }}"
                alt="icon" />
              <span [ngClass]="{'hidden lg:block' : !!header.image}">{{ header.title }}</span>
            </button>
          </div>
          <div class="shrink-0" *ngIf="header.children">
            <div
              class="relative group"
              #menuParent
              [routerLink]="[header.url]"
              title="{{ header.title }}"
              [disableLink]="true">
              <a
                routerLinkActive="!border-black bg-secondary-300.5 text-secondary-500"
                (click)="toggleDropdown($event, menuParent)"
                class="w-full rem:h-[72px] px-4 flex justify-center items-center flex-nowrap gap-2 text-sm font-medium leading-6 text-black border-b-2 border-transparent">
                <img
                  class="w-fit rem:h-[16px]"
                  src="../assets/fonts/{{ header.image }}"
                  alt="icon" />
                <span class="hidden lg:block">{{ header.title }}</span>
              </a>

              <div
                [ngClass]="
                  isExpanded(menuParent)
                    ? 'transition east-out duration-300 transform opacity-100 scale-100 expanded'
                    : 'transition ease-in duration-75 transform opacity-0 scale-95 collapsed'
                "
                class="absolute left-[-100px] lg:left-0 bg-white p-1 space-y-1 min-w-[180px] border border-black w-full">
                <ul>
                  <li *ngFor="let child of header.children">
                    <a
                      appSetAriaCurrentOnActiveLink
                      [routerLink]="child.url"
                      [queryParams]="child.queryParams"
                      class="p-2 h-10 flex items-center text-sm hover:bg-secondary-100">
                      <span>{{ child.title }} </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <button
        type="button"
        class="relative w-fit h-10 group flex md:hidden items-center justify-center"
        data-dropdown-toggle="mobile-menu">
        <span class="text-gray-800 text-xl">
          <img
            class="rem:w-fit text-end rem:h-[28px]"
            src="../assets/fonts/icon-hamburger.svg"
            alt="Menu"
        /></span>
      </button>
    </header>

    <div
      id="mobile-menu"
      class="max-w-80 p-4 transition-all bg-white border border-black hidden md:hidden">
      <nav>
        <ul class="flex flex-col gap-2">
          <ng-container *ngFor="let header of allMenuItems">
            <li class="shrink-0 pl-4 py-2 hover:bg-secondary-100" *ngIf="!header.children">
              <a
                routerLinkActive=" text-secondary-500"
                appSetAriaCurrentOnActiveLink
                [routerLink]="header.url"
                [queryParams]="header.queryParams"
                class="text-sm font-medium"
                >{{ header.title }}</a
              >
            </li>

            <li class="shrink-0 pl-4 has-sub-menu" *ngIf="header.children">
              <div class="relative group transition-all duration-300 cursor-pointer" #menuParent>
                <a class="text-secondary-800" (click)="toggleDropdown($event, menuParent)">
                  <div class="w-full py-2 pr-4 text-left cursor-pointer">
                    <div class="flex items-center justify-between">
                      <span
                        [routerLink]="header.url"
                        routerLinkActive="text-secondary-500"
                        [disableLink]="true"
                        class="font-medium text-sm"
                        >{{ header.title }}</span
                      >
                      <span class="transition-transform duration-500 transform fill-current">
                        <img
                          class="w-fit text-end rem:h-[14px] rotate-90"
                          src="../assets/fonts/icon-arrow-expand.svg"
                          alt="icon" />
                      </span>
                    </div>
                  </div>
                </a>
                <div
                  class="relative overflow-hidden transition-all duration-700"
                  [ngClass]="  isExpanded(menuParent)
                  ? 'transition east-out duration-75 transform opacity-100 scale-100 '
                  : 'transition ease-in duration-75 transform opacity-0 scale-95 max-h-0'
              ">
                  <div
                    class="pb-2 px-4 w-full hover:bg-secondary-100"
                    *ngFor="let child of header.children">
                    <a
                      routerLinkActive="text-secondary-500"
                      appSetAriaCurrentOnActiveLink
                      [routerLink]="child.url"
                      [queryParams]="child.queryParams"
                      class="text-sm font-medium py-2"
                      >{{ child.title }}</a
                    >
                  </div>
                </div>
              </div>
            </li>
          </ng-container>
        </ul>
      </nav>
    </div>
  </div>
</div>
