import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService, GenericError } from '@auth0/auth0-angular'
import { PingService } from '@cts/cedar-public-api'
import { ApiErrorInterceptor } from 'projects/cedar-shared/src/interceptors/api-error.interceptor'
import { firstValueFrom, Subject, takeUntil } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class HealthCheckService {
  private stopListening = new Subject<void>()

  constructor(
    private authService: AuthService,
    private pingService: PingService,
    private router: Router,
    private apiErrorInterceptor: ApiErrorInterceptor
  ) {}

  async initialise(): Promise<boolean | string> {
    console.log('running health checks')

    this.stopListening.next()

    var tokenCheckResult = await this.runTokenCheck()

    if (tokenCheckResult !== true) {
      console.error('(Refresh) token check failed.')
      return tokenCheckResult
    }

    if (!(await this.runPingCheck())) {
      console.error('Ping check failed.')
      return 'error'
    }

    this.authService.error$.pipe(takeUntil(this.stopListening)).subscribe((authError) => {
      console.log(authError)
      var redirect = this.getRedirectFromError(authError as GenericError)
      this.router.navigate([redirect])
    })

    this.apiErrorInterceptor.error$.pipe(takeUntil(this.stopListening)).subscribe((apiError) => {
      console.log(apiError)
      this.router.navigate(['error'])
    })

    return true
  }

  private getRedirectFromError(error: GenericError): string {
    if (error.error === 'invalid_grant' && error.message == 'Unknown or invalid refresh token.') {
      return 'log-in'
    } else return 'log-out'
  }

  private async runTokenCheck(): Promise<boolean | string> {
    var isAuthenticated = await firstValueFrom(this.authService.isAuthenticated$)
    if (isAuthenticated) {
      try {
        await firstValueFrom(this.authService.getAccessTokenSilently())
      } catch (e) {
        return this.getRedirectFromError(e as GenericError)
      }
    }
    return true
  }

  private async runPingCheck(): Promise<boolean> {
    try {
      await firstValueFrom(this.pingService.runPingTest())
      return true
    } catch (e) {
      console.log(e)
      return false
    }
  }
}
